import React from "react";
import "./ChallengeCard.scss";
import { Link } from "react-router-dom";

const ChallengeCard = (props) => {
  const lines = props.person.split("\n");
  return (
    <Link to={`/registration?discipline=${encodeURIComponent(props.type)}`}>
      <div className="chalCard">
        <h2 className="chalCard__price">{props.price}</h2>
        <p className="chalCard__type">{props.type}</p>
        <p className="chalCard__person">
          {lines.map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </p>
      </div>
    </Link>
  );
};

export default ChallengeCard;
